import React, {FunctionComponent, useEffect, useState} from 'react';
import {wrap} from 'popmotion';
import {AnimatePresence, motion} from 'framer-motion';
import {GatsbyImage, IGatsbyImageData} from 'gatsby-plugin-image';
import {graphql, useStaticQuery} from 'gatsby';
import Images from '../types/Images';

const HeroImage: FunctionComponent = () => {
    const heroImages: Images = useStaticQuery(graphql`
                query HeroSliderImageQuery {
      allFile(filter: {
                    relativeDirectory: { regex: "/(hero-slider)/"}
            }) {
                edges {
                    node {
                        name
                        childImageSharp {
                            gatsbyImageData(width: 1440, quality: 90)
                        }
                    }
                }
            }
    }`).allFile;

    return (
        (heroImages.edges.length === 1 && heroImages.edges[0]) ?
            <div className='relative mx-auto max-w-screen-2xl'>
                <GatsbyImage
                    image={heroImages.edges[0].node.childImageSharp.gatsbyImageData}
                    alt={heroImages.edges[0].node.name}
                />
            </div>
            :
            <div className='relative mx-auto max-w-screen-2xl'>
                <Slider images={heroImages.edges.map((edge) => edge.node.childImageSharp.gatsbyImageData)}/>
            </div>
    );
};

export default HeroImage;

// Brutally copied and adapted to our needs from: https://codesandbox.io/s/framer-motion-image-gallery-pqvx3?file=/src/Example.tsx

interface SliderProps {
    images: Array<IGatsbyImageData>
}

const Slider: FunctionComponent<SliderProps> = ({images}) => {
    const [[page, direction], setPage] = useState([0, 0]);
    const imageIndex = wrap(0, images.length, page);

    const paginate = (newDirection: number) => {
        setPage([page + newDirection, newDirection]);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            paginate(1);
        }, 6000);
        return () => clearInterval(interval);
    }, [page]);

    return (
        <div style={{paddingBottom: 'calc(383 / 1280 * 100%)'}}>
            <AnimatePresence initial={false} custom={direction}>
                <motion.div
                    key={page}
                    className='absolute'
                    custom={direction}
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{
                        x: {type: 'spring', stiffness: 300, damping: 200},
                        opacity: {duration: 0.9}
                    }}
                    drag={'x'}
                    dragElastic={1}
                >
                    <GatsbyImage image={images[imageIndex] as IGatsbyImageData} alt={''}/>
                </motion.div>
            </AnimatePresence>
        </div>
    );
};
